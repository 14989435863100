@font-face {
  font-family: 'comfortaalight';
  src: url('/fonts/comfortaa-light-webfont.woff2') format('woff2'),
    url('/fonts/comfortaa-light-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'comfortaaregular';
  src: url('/fonts/comfortaa-regular-webfont.woff2') format('woff2'),
    url('/fonts/comfortaa-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'comfortaabold';
  src: url('/fonts/comfortaa-bold-webfont.woff2') format('woff2'),
    url('/fonts/comfortaa-bold-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'somaticrounded';
  src: url('/fonts/somatic-rounded-webfont.woff2') format('woff2'),
    url('/fonts/somatic-rounded-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

html,
body {
  overflow-x: hidden;
}

.swiper-custom .swiper-wrapper {

  @media screen and (min-width: 600px) {
    justify-content: center;
  }
}

.contador span {
  /* color: #your-secondary-color; */
  background: white;
  box-shadow: 0px 0px 6px #36363620;
  height: 30px;
  min-width: 30px;
  padding: 0px 6px;
  border-radius: 4px;
  margin: 2px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  /* font-family: 'poppinsbold'; */
  line-height: 1;
}

.contador {
  /* color: #your-primary-color; */
  display: flex;
  justify-content: center;
}

.contador p {
  margin: 0px 10px;
  top: 8px;
  font-size: 22px;
  line-height: 1.2;
  position: relative;
}

.contador h4 {
  /* color: #your-primary-color; */
  text-align: center;
  /* font-family: 'poppinssemibold'; */
}

