@keyframes marquee {
  0% {
    transform: translateX(-200%);
  }
  100% {
    transform: translateX(200%);
  }
}

.marquee-container {
  text-align: center;
  background-color: #e75a29;
  display: flex;
  color: white;
  justify-content: center;
  padding: 8px;
  font-size: 16px;
  gap: 10px;
  display: flex;
  width: 100%;
}

.marquee-container > .wrapper {
  animation: marquee 40s linear infinite;
  display: flex;
  align-items: center;
  gap: 16px;
  text-wrap: nowrap;
}
